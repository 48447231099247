<template>
  <CForm ref="form" class="needs-validation">
    <CSelect
        label="Kargo Şirketi: "
        placeholder="Seçiniz.."
        horizontal
        :options="shippingCompanies"
        :value.sync="data.shippingCompanyId"
    />
    <CInput
        label="Takip No: "
        horizontal
        type="text"
        v-model="data.shippingTrackingNumber"
    />
    <CInput
        label="Tutar(TL): "
        horizontal
        v-model="data.shippingPrice"
    />
    <CInput
        label="Ek Ücret(Vergi): "
        horizontal
        v-model="data.shippingExtraPrice"
    />
    <CInput
        label="ETGB No: "
        horizontal
        type="text"
        v-model="data.shippingEtgbNo"
    />
    <CInput
        label="ETGB Tarihi"
        type="date"
        horizontal
        v-model="data.shippingEtgbDate"
    />
  </CForm>
</template>

<script>
export default{
  name: "CargoInfoForm",
  props:{
    validated: Boolean,
    params: Object,
    actionType: String,
  },
  watch:{
    params: function(val){
      this.data = {...val};
    },
    actionType:  function(val){
      if(val == 'create'){
        this.data = {...this.params};
      }
    },
  },
  computed: {
    shippingCompanies: function(){
      let data = []
      this.$store.getters.regionShippingCompanies.map(r => data.push({value:r.id, label: r.name}))
      return data
    }
      
  },
  data: function(){
    return{
      data: this.params ? {...this.params} : {},

    }
  }
}
</script>